// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xbuXvl0n4B6JQErq\\+EPegQ\\=\\={position:sticky;top:20px;margin:0;padding:0 0 0 24px;font-size:var(--body-1);list-style:none}.A8\\+C88vrY-oBRlRTuEqcgQ\\=\\={padding-top:200px}.xbuXvl0n4B6JQErq\\+EPegQ\\=\\=::before{position:absolute;top:0;bottom:0;left:0;width:2px;background:-webkit-gradient(linear, left top, left bottom, from(var(--va-purple-500)), to(var(--va-pink-A400)));background:linear-gradient(var(--va-purple-500), var(--va-pink-A400));content:''}.xbuXvl0n4B6JQErq\\+EPegQ\\=\\= li{margin-bottom:10px}@media only screen and (max-width: 1200px){.A8\\+C88vrY-oBRlRTuEqcgQ\\=\\={display:none}}\n", "",{"version":3,"sources":["webpack://./src/components/FloatingNavigation/FloatingNavigation.scss"],"names":[],"mappings":"AAAA,6BACI,eAAgB,CAChB,QAAS,CACT,QAAS,CACT,kBAAmB,CACnB,uBAAwB,CACxB,eAAgB,CAEhB,6BACI,iBAAkB,CAT1B,qCAaQ,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,MAAO,CACP,SAAU,CACV,+GAAsE,CAAtE,qEAAsE,CACtE,UAAW,CAnBnB,gCAuBQ,kBAAmB,CACtB,2CAGL,6BAEQ,YAAa,CAEpB","sourcesContent":[".floatingNavigation {\n    position: sticky;\n    top: 20px;\n    margin: 0;\n    padding: 0 0 0 24px;\n    font-size: var(--body-1);\n    list-style: none;\n\n    &Container {\n        padding-top: 200px;\n    }\n\n    &::before {\n        position: absolute;\n        top: 0;\n        bottom: 0;\n        left: 0;\n        width: 2px;\n        background: linear-gradient(var(--va-purple-500), var(--va-pink-A400));\n        content: '';\n    }\n\n    li {\n        margin-bottom: 10px;\n    }\n}\n\n.floatingNavigationContainer {\n    @media only screen and (max-width: 1200px) {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"floatingNavigation": "xbuXvl0n4B6JQErq+EPegQ==",
	"floatingNavigationContainer": "A8+C88vrY-oBRlRTuEqcgQ=="
};
export default ___CSS_LOADER_EXPORT___;
