// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QllDGcZ1ubhLoWkqFethZg\\=\\= h1{font-size:2rem;margin-bottom:0.75rem}.QllDGcZ1ubhLoWkqFethZg\\=\\= h3{font-size:1.25rem;margin-bottom:0.75rem}.QllDGcZ1ubhLoWkqFethZg\\=\\= p{margin-bottom:0.625rem;line-height:1.5}.QllDGcZ1ubhLoWkqFethZg\\=\\= section{margin-bottom:2rem}._5DCjSdBJtAOv5sxa0k-J5g\\=\\={width:1.5rem;height:1.5rem;display:inline-block}\n", "",{"version":3,"sources":["webpack://./src/waveform/waveformDoc.scss"],"names":[],"mappings":"AAAA,+BAEQ,cAAe,CACf,qBAAsB,CAH9B,+BAMQ,iBAAkB,CAClB,qBAAsB,CAP9B,8BAUQ,sBAAuB,CACvB,eAAgB,CAXxB,oCAcQ,kBAAmB,CACtB,6BAGD,YAAa,CACb,aAAc,CACd,oBAAqB","sourcesContent":[".waveformDocumentation {\n    h1 {\n        font-size: 2rem;\n        margin-bottom: 0.75rem;\n    }\n    h3 {\n        font-size: 1.25rem;\n        margin-bottom: 0.75rem;\n    }\n    p {\n        margin-bottom: 0.625rem;\n        line-height: 1.5;\n    }\n    section {\n        margin-bottom: 2rem;\n    }\n}\n.waveformColorSwatch {\n    width: 1.5rem;\n    height: 1.5rem;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"waveformDocumentation": "QllDGcZ1ubhLoWkqFethZg==",
	"waveformColorSwatch": "_5DCjSdBJtAOv5sxa0k-J5g=="
};
export default ___CSS_LOADER_EXPORT___;
