"use strict";
exports.__esModule = true;
exports.tableOfContents = void 0;
var types_1 = require("./types");
exports.tableOfContents = [
    {
        title: 'Mixer',
        slug: 'mixer',
        templateType: types_1.TemplateType.Documentation,
        children: [
            {
                title: 'Getting Started',
                slug: 'getting-started',
                templateType: types_1.TemplateType.Documentation
            },
            {
                title: 'Themes',
                slug: 'themes',
                templateType: types_1.TemplateType.Documentation
            },
            {
                title: 'Typography',
                slug: 'typography',
                templateType: types_1.TemplateType.Documentation
            },
            {
                title: 'Grid',
                slug: 'grid',
                templateType: types_1.TemplateType.Documentation
            },
            {
                title: 'Utilities',
                slug: 'utilities',
                templateType: types_1.TemplateType.Documentation
            },
            {
                title: 'Colors',
                slug: 'colors',
                templateType: types_1.TemplateType.Documentation
            }
        ]
    },
    {
        title: 'Core',
        slug: 'core',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'DatePicker',
        slug: 'datepicker',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'PreAmp+',
        slug: 'preamp-plus',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'Rev',
        slug: 'rev',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'Select',
        slug: 'select',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'Signal',
        slug: 'signal',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'Tables',
        slug: 'tables',
        templateType: types_1.TemplateType.Component
    },
    {
        title: 'Waveform',
        slug: 'waveform',
        templateType: types_1.TemplateType.Component
    }
];
