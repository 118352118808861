// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XhSpxC9IkYM1Oq79CWmfUg\\=\\={--va-navlink-active: var(--va-purple-500);color:var(--va-grey-900);text-decoration:none}.XhSpxC9IkYM1Oq79CWmfUg\\=\\=:hover{color:var(--va-purple-500)}._9zAbcn8p0eELXDipbrVGsw\\=\\={color:var(--va-navlink-active)}._9zAbcn8p0eELXDipbrVGsw\\=\\=:hover,._9zAbcn8p0eELXDipbrVGsw\\=\\=:focus{color:var(--va-navlink-active)}\n", "",{"version":3,"sources":["webpack://./src/components/NavigationLink/NavigationLink.scss"],"names":[],"mappings":"AAEA,4BACI,yCAAoB,CACpB,wBAAyB,CACzB,oBAAqB,CAHzB,kCAMQ,0BAA2B,CAC9B,6BAGG,8BAA+B,CADlC,sEAKO,8BAA+B","sourcesContent":["@import '../../sass/documentation-helpers';\n\n.navigationLink {\n    --va-navlink-active: var(--va-purple-500);\n    color: var(--va-grey-900);\n    text-decoration: none;\n\n    &:hover {\n        color: var(--va-purple-500);\n    }\n\n    &Active {\n        color: var(--va-navlink-active);\n\n        &:hover,\n        &:focus {\n            color: var(--va-navlink-active);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationLink": "XhSpxC9IkYM1Oq79CWmfUg==",
	"navigationLinkActive": "_9zAbcn8p0eELXDipbrVGsw=="
};
export default ___CSS_LOADER_EXPORT___;
