// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7yqL0IJYEOwAKJJdYTC4Vg\\=\\={background:var(--va-grey-200)}.f7W0dKCApuTVntjnYBQ0ZA\\=\\=::after{content:'';display:block;height:2px;width:100%;margin-top:2rem;background:-webkit-gradient(linear, right top, left top, from(var(--va-purple-500)), to(var(--va-pink-A400)));background:linear-gradient(to left, var(--va-purple-500), var(--va-pink-A400))}.f7W0dKCApuTVntjnYBQ0ZA\\=\\=:last-of-type::after{display:none}\n", "",{"version":3,"sources":["webpack://./src/components/Example/Example.scss"],"names":[],"mappings":"AACI,6BACI,6BAA8B,CAFtC,mCAMQ,UAAW,CACX,aAAc,CACd,UAAW,CACX,UAAW,CACX,eAAgB,CAChB,6GAAY,CAAZ,8EAIC,CAfT,gDAmBQ,YAAa","sourcesContent":[".example {\n    &-nav {\n        background: var(--va-grey-200);\n    }\n\n    &::after {\n        content: '';\n        display: block;\n        height: 2px;\n        width: 100%;\n        margin-top: 2rem;\n        background: linear-gradient(\n            to left,\n            var(--va-purple-500),\n            var(--va-pink-A400)\n        );\n    }\n\n    &:last-of-type::after {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"example-nav": "_7yqL0IJYEOwAKJJdYTC4Vg==",
	"example": "f7W0dKCApuTVntjnYBQ0ZA=="
};
export default ___CSS_LOADER_EXPORT___;
