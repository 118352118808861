"use strict";
exports.__esModule = true;
exports.packageDetails = void 0;
var path = require("path");
exports.packageDetails = [
    {
        packageName: 'core',
        packagePath: path.join(__dirname, '../../..', 'core', 'components')
    },
    {
        packageName: 'datepicker',
        packagePath: path.join(__dirname, '../../..', 'datepicker', 'components')
    },
    {
        packageName: 'preamp-plus',
        packagePath: path.join(__dirname, '../..', 'node_modules', '@videoamp-private', 'preamp-plus', 'src', 'components')
    },
    {
        packageName: 'rev',
        packagePath: path.join(__dirname, '../../..', 'rev', 'components')
    },
    {
        packageName: 'select',
        packagePath: path.join(__dirname, '../../..', 'select', 'components')
    },
    {
        packageName: 'signal',
        packagePath: path.join(__dirname, '../../..', 'signal', 'components')
    },
    {
        packageName: 'tables',
        packagePath: path.join(__dirname, '../../..', 'tables', 'components')
    },
    {
        packageName: 'waveform',
        packagePath: path.join(__dirname, '../../..', 'waveform', 'components')
    }
];
