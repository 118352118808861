import component from '../compiled-data/componentData';
import documentation from '../compiled-data/documentationData';

if (documentation.errors.length) {
    throw new Error(
        `Loading documentationData: \n\n${documentation.errors.join('\n')}`
    );
}

if (component.errors.length) {
    throw new Error(
        `Loading componentData: \n\n${component.errors.join('\n')}`
    );
}

const componentData = component.data;
const documentationData = documentation.data;

export { componentData, documentationData };
