var api = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../node_modules/@teamsupercell/typings-for-css-modules-loader/src/index.js!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[3]!../../../node_modules/sass-loader/dist/cjs.js!./Docs.scss");

            content = content.__esModule ? content.default : content;

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};